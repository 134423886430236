<template>
  <footer class="site-footer">
    <div class="container">
      <div class="site-footer-inner">
        <div class="brand footer-brand">
        </div>
        <ul class="footer-links list-reset">
          <li>
            <a href="mailto:g.belanger1996@gmail.com">Contact</a>
          </li>
          <li>
            <a href="https://www.facebook.com/RTXgang">About us</a>
          </li>
        </ul>
        <ul class="footer-social-links list-reset">
          <li>
            <a href="https://www.facebook.com/RTXgang">
              <span class="screen-reader-text">Facebook</span>
              <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                  fill="#0270D7"
                />
              </svg>
            </a>
          </li>
        </ul>
        <div class="footer-copyright">
          &copy; 2021 RTX-Gang, all rights reserved
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>