<template>
  <div class="body-wrap">
  <Header/>
  <Main/>
  <Footer/>
  </div>
</template>

<script>
import Header from './header.vue'
import Footer from './footer.vue'
import Main from './main.vue'
export default {
  components: { Header, Footer, Main },
  name: 'LandingPage',
}
</script>

<style >
</style>
