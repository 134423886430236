<template>
  <main>
    <section class="hero">
      <div class="container">
        <div class="hero-inner">
          <div class="hero-copy">
            <h1 class="hero-title mt-0">RTX Gang</h1>
            <p class="hero-paragraph">Fastest Gpu Tracker in Canada</p>
            <div class="hero-cta">
              <a
                class="button button-primary"
                href="https://upgrade.chat/checkout/798299884334874634"
                >Get Notified now</a
              >
              <a class="button" href="https://www.facebook.com/RTXgang"
                >Get in touch</a
              >
            </div>
            <div style="margin-top:16px;">
                <iframe src="https://discord.com/widget?id=798299884334874634&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
          </div>
        <cube-elements/>
        </div>
      </div>
    </section>

    <section class="features section">
      <div class="container">
        <div class="features-inner section-inner has-bottom-divider">
          <div class="features-wrap">
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">Ricko6411</h4>
                <p class="text-sm mb-0">
                  S/O my man @Guillaume wouldn't have a full rig without RTXgang
                  &#128591; &#128170;
                </p>
              </div>
            </div>
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">Mabros</h4>
                <p class="text-sm mb-0">Thanks @Guillaume for pimpin' my rig</p>
              </div>
            </div>
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">Finessity</h4>
                <p class="text-sm mb-0">
                  @Guillaume drawing smiles on ppls faces
                </p>
              </div>
            </div>
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">Mot Sille</h4>
                <p class="text-sm mb-0">
                  Also would like to thank @Guillaume for having one of the best
                  notifs out there
                </p>
              </div>
            </div>
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">XrayDsm</h4>
                <p class="text-sm mb-0">
                  Thank you @Guillaume finally passed that fictional wall of
                  1GH/s
                </p>
              </div>
            </div>
            <div class="feature text-center is-revealing">
              <div class="feature-inner">
                <h4 class="feature-title mt-24">PidouLion</h4>
                <p class="text-sm mb-0">To any who doubt it's possible to get a card even with this bot, just be a bit patient and you'll have it ! </p>
                <p class="text-sm mb-0"> thank you @Guillaume who could have charged us SO much more for this service !</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pricing section">
      <div class="container-sm">
        <div class="pricing-inner section-inner">
          <div class="pricing-header text-center">
            <h2 class="section-title mt-0">Unlimited for all</h2>
            <p class="section-paragraph mb-0">
              Fastest real time tracker for both AMD and Nvidia cards
            </p>
          </div>
          <div class="pricing-tables-wrap">
            <div class="pricing-table">
              <div class="pricing-table-inner is-revealing">
                <div class="pricing-table-main">
                  <div class="pricing-table-header pb-24">
                    <div class="pricing-table-price">
                      <span class="pricing-table-price-currency h2">$</span>
                      <span class="pricing-table-price-amount h1">6</span>
                      <span class="text-xs">/month</span>
                    </div>
                  </div>
                  <div class="pricing-table-features-title text-xs pt-24 pb-24">
                    Tracked websites
                  </div>
                  <ul class="pricing-table-features list-reset text-xs">
                    <li>
                      <span>BestBuy.ca</span>
                    </li>
                    <li>
                      <span>Newegg.ca</span>
                    </li>
                    <li>
                      <span>CanadaComputers.com</span>
                    </li>
                    <li>
                      <span>Amazon.ca</span>
                    </li>
                    <li>
                      <span>Amd.com</span>
                    </li>
                  </ul>
                </div>
                <div class="pricing-table-cta mb-8">
                  <a
                    class="button button-primary button-shadow button-block"
                    href="https://discord.gg/xEPT7cduMe"
                    >Visit our discord</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cta section">
      <div class="container">
        <div class="cta-inner section-inner">
          <h3 class="section-title mt-0">Still not convinced on buying?</h3>
          <div class="cta-cta">
            <a class="button button-primary button-wide-mobile" href="#"
              >Get in touch</a
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import cubeElements from './cube-elements.vue';
export default {
  components: { cubeElements },
  name: "Main",
};
</script>