<template>
  <div id="app">
    <LandingPage/>
  </div>
</template>

<script>
import LandingPage from './components/landingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
@import './assets/styles.css';
</style>
