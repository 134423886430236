<template>
    <header class="site-header">
        <div class="container">
            <div class="site-header-inner">
                <div class="brand header-brand">
                    <h1 class="m-0">
                        <a href="#">
                        </a>
                    </h1>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>