<template>
  <div class="hero-figure anime-element">
    <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
      <rect width="528" height="396" style="fill: transparent" />
    </svg>
    <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
    <div
      class="hero-figure-box hero-figure-box-02"
      data-rotation="-45deg"
    ></div>
    <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div>
    <div
      class="hero-figure-box hero-figure-box-04"
      data-rotation="-135deg"
    ></div>
    <div class="hero-figure-box hero-figure-box-05"></div>
    <div class="hero-figure-box hero-figure-box-06"></div>
    <div class="hero-figure-box hero-figure-box-07"></div>
    <div
      class="hero-figure-box hero-figure-box-08"
      data-rotation="-22deg"
    ></div>
    <div
      class="hero-figure-box hero-figure-box-09"
      data-rotation="-52deg"
    ></div>
    <div
      class="hero-figure-box hero-figure-box-10"
      data-rotation="-50deg"
    ></div>
  </div>
</template>
<script>
export default {
  name: "Cube-Elements",
};
</script>